<template>
  <v-dialog
    v-model="show"
    max-width="1000"
    @keydown.esc="closeWindow"
  >
    <v-card
      v-click-outside="closeWindow"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-end">
        <v-btn
          color="error"
          small
          @click="closeWindow"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :items="parseRequirements"
        :headers="headers"
        class="rounded-0"
        fixed-header
        hide-default-footer
        disable-sort
        height="400px"
      >
        <template #[`item.docNum`]="{item}">
          <div
            v-for="element in item.documents"
            :key="element.number"
          >
            <span>{{ element.number }}</span>
          </div>
        </template>
        <template #[`item.docDate`]="{item}">
          <div
            v-for="element in item.documents"
            :key="element.number"
          >
            <span>{{ formatDate(element.date) }}</span>
          </div>
        </template>
        <template #[`item.goodNum`]="{item}">
          <div
            v-for="element in item.documents"
            :key="element.number"
          >
            <span>{{ element.wareNumberFromDt }}</span>
          </div>
        </template>
      </v-data-table>
      <div class="white pa-5 rounded-0">
        <div class="d-flex justify-end mt-4">
          <v-btn
            color="#5CB7B1"
            elevation="0"
            dark
            @click="createKdtKts"
          >
            Создать КДТ КТС
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>


import formatDate from '@/mixins/format-date.js'
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";

export default {
  mixins: [modalWindowMixin, formatDate],
  props:{
    show: {
      required: true,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    },
  },
  data: () => ({
    headers: [
      {
        text: "№ товара",
        value: "dtSortIndex",
      },
      {
        text: "Наименование",
        value: "description",
      },
      {
        text: "Таможенная стоимость",
        value: "customsCost",
      },
      {
        text:"№ документа",
        value:"docNum",
      },
      {
        text:"Дата документа",
        value:"docDate",
      },
      {
        text:"№ товара в документе",
        value:"goodNum",
      },
    ]
  }),
  computed: {
    ...mapGetters({
      parseRequirements: 'kdt/parseRequirements'
    }),
  },
  methods: {
    createKdtKts() {
      this.$store.dispatch('dt/createKdtKts', {declarationId: this.item.id, payload: this.parseRequirements})
        .then(({ data }) => {
          const route = this.$router.resolve({ path: `/kdt/${data}` });
          window.open(route.href, "_blank");
        })
        .catch((err) => this.$error(err.response.data));
    },
    closeWindow() {
      this.$emit("update:show", false);
    },
  }
}
</script>

<style scoped>  
.v-data-table >>> tr:hover {
  background: transparent !important;
}
</style>